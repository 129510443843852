/* eslint-disable react/display-name */
import React from 'react';
import { graphql, withPrefix } from 'gatsby';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import CardWorkLong from '../components/CardWorkLong';
import ContentfulImage from '../components/ContentfulImage';
import CardAuthor from '../components/CardAuthor';

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const imageId = node.data.target.sys.id;
      return <ContentfulImage id={imageId} />;
    },
  },
};

const Work = ({ data }) => {
  const { cover, slug, title } = data.contentfulWorks;
  const { raw, references } = data.contentfulWorks.desc;
  const bodyHtml = JSON.parse(raw);

  return (
    <Layout bodyClass='page-default-single'>
      <div className='container pb-6 pt-6 pt-md-10 pb-md-10'>
        <div className='row justify-content-center article-content'>
          <div className='col-12 col-md-8'>
            <h1 className='title'>{title}</h1>
            <div className='content'>
              {documentToReactComponents(bodyHtml, options)}
            </div>
            <CardAuthor />
          </div>
        </div>
        <div className='row'>
          <CardWorkLong data={data.contentfulWorks} noAnimation />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    contentfulWorks(slug: { eq: $slug }) {
      id
      background
      country
      slug
      stack
      short {
        short
      }
      title
      cover {
        id
        file {
          url
        }
        title
        gatsbyImageData(
          width: 600
          height: 450
          placeholder: BLURRED
          formats: [AUTO, PNG]
        )
      }
      desc {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            fluid(maxWidth: 600, quality: 85) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      service {
        title
      }
    }
    allSocialJson {
      edges {
        node {
          name
          image
          link
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const { cover, slug, title } = data.contentfulWorks;
  return (
    <>
      <SEO
        title={`${title} - Projects`}
        url={`projects/${slug}`}
        image={`https:${cover?.file?.url}`}
      />
      <meta property='og:type' content='article' />
    </>
  );
};

export default Work;
